import { Helmet } from "react-helmet";
import favicon from "../../assets/favicon.ico";
import photo from "../../assets/me-pro.jpeg";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { StyledLink } from "../../utils/styles/Atoms";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons"; // ou '@fortawesome/free-regular-svg-icons'

const MenuWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ImgHome = styled.div`
  width: 2.25rem;
  height: 2.25rem;
`;

const NavContainer = styled.div``;

function Header() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Christopher Rocha Dias - Développeur Fullstack Symfony / React{" "}
        </title>
        <link rel="icon" href={favicon} />
        <meta
          name="description"
          content="Christopher Rocha Dias - Développeur Fullstack Symfony / React"
        />
      </Helmet>
      <header className="sm:container sm:mx-auto">
        <MenuWrapper id="menu" className="menuBar">
          <div className="navbar bg-base-100">
            <div className="navbar-start">
              <div className="dropdown">
                <div
                  tabIndex={0}
                  role="button"
                  className="btn btn-ghost lg:hidden"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 6h16M4 12h8m-8 6h16"
                    />
                  </svg>
                </div>
                <ul
                  tabIndex={0}
                  className="menu menu-sm dropdown-content bg-base-100 rounded-box z-[1] mt-3 w-52 p-2 shadow"
                >
                  <li>
                    <StyledLink to="/">À Propos</StyledLink>
                  </li>
                  <li>
                    <StyledLink to="/career">Carrière</StyledLink>
                  </li>
                  <li>
                    <StyledLink to="/projects">Projets</StyledLink>
                  </li>
                  <li>
                    <StyledLink to="/contact">Contact</StyledLink>
                  </li>
                </ul>
              </div>
              <Link to="/" className="btn btn-ghost text-xl">
                <ImgHome className="avatar">
                  <div className="ring-primary ring-offset-base-100 w-24 rounded-full ring ring-offset-2">
                    <img alt="Logo accueil" src={photo} className="w-10 h-10" />
                  </div>
                </ImgHome>
              </Link>
            </div>
            <NavContainer className="navbar-center hidden lg:flex">
              <ul className="menu menu-horizontal px-1">
                <li>
                  <StyledLink to="/">À Propos</StyledLink>
                </li>
                <li>
                  <StyledLink to="/career">Carrière</StyledLink>
                </li>
                <li>
                  <StyledLink to="/projects">Projets</StyledLink>
                </li>
                <li>
                  <StyledLink to="/contact">Contact</StyledLink>
                </li>
                <li>
                  <StyledLink
                    target="_blank"
                    to="https://admin.christopher-rochadias.com/assets/uploads/Cv-ChristopherRochaDias.pdf"
                  >
                    <FontAwesomeIcon icon={faDownload} /> CV
                  </StyledLink>
                </li>
              </ul>
            </NavContainer>

            <div className="navbar-end">
              {/* <input
                type="checkbox"
                value="synthwave"
                className="checkbox theme-controller"
              /> */}
            </div>
          </div>
        </MenuWrapper>
      </header>
    </>
  );
}

export default Header;
