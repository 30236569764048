import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons"; // ou '@fortawesome/free-regular-svg-icons'

import {
  faLinkedin,
  faGithub,
  faGitlab,
} from "@fortawesome/free-brands-svg-icons";

function Footer() {
  return (
    <footer className="footer footer-center rounded hidden sm:contents md:contents lg:grid">
      <nav className="grid grid-flow-col gap-4">
        {/* <a className="link link-hover">Politique de confidentialité</a>
        <a className="link link-hover"> Mentions légales</a> */}
      </nav>
      <nav>
        <div className="grid grid-flow-col gap-4">
          <a
            className=""
            href="https://www.linkedin.com/in/christopher-rocha-dias/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faLinkedin} />
            <span className="ml-4 text-lg">LinkedIn</span>
          </a>
          <a
            className=""
            href="mailto:christopher.rochadias@gmail.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faEnvelope} />
            <span className="ml-4 text-lg">
              christopher.rochadias@gmail.com
            </span>
          </a>
          <a
            className=""
            href="https://github.com/chrrochadias"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faGithub} />
            <span className="ml-4 text-lg">Github</span>
          </a>
          <a
            className=""
            href="https://gitlab.com/christopher.rochadias"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faGitlab} />
            <span className="ml-4 text-lg">Gitlab</span>
          </a>
        </div>
      </nav>
      <aside>
        <p>Copyright © {new Date().getFullYear()} - Christopher Rocha Dias</p>
      </aside>
    </footer>
  );
}

export default Footer;
