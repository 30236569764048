import React, { createContext, useContext } from "react";

const ApiUrlContext = createContext<string | undefined>(undefined);

export const ApiProvider = ({ children }: { children: React.ReactNode }) => {
  const apiUrl = process.env.REACT_APP_API_URL;

  return (
    <ApiUrlContext.Provider value={apiUrl}>{children}</ApiUrlContext.Provider>
  );
};

export const useApiUrl = () => {
  const context = useContext(ApiUrlContext);
  if (!context) {
    throw new Error("useApiUrl must be used within an ApiProvider");
  }
  return context;
};
