import React from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import styled from "styled-components";
import Title from "../../components/Title/Title";
import axios from "axios";
import { useApiUrl } from "../../utils/Context/ApiContext";

interface IFormInput {
  name: string;
  email: string;
  message: string;
}

const ContactContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 32vh;
  border-radius: 0.5rem;
  border: 4px solid grey;
`;

function ContactForm() {
  const apiUrl = useApiUrl();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInput>();

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    axios
      .post(`${apiUrl}/api/contact`, JSON.stringify(data), {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const Toast = document.getElementById("toastSuccessMail");
        Toast?.classList.remove("hidden");
        setTimeout(() => {
          Toast?.classList.add("hidden");
        }, 5000);
        const SendButton = document.getElementById("sendMail");
        SendButton?.setAttribute("disabled", "true");
      })
      .catch((error) => {
        if (error.response) {
          // La requête a été faite et le serveur a répondu avec un code d'erreur
        } else if (error.request) {
          // La requête a été faite mais aucune réponse n'a été reçue
        } else {
          // Quelque chose s'est mal passé lors de la configuration de la requête
        }
      });
  };

  return (
    <ContactContainer className="container mx-auto gap-4 p-4 m-4">
      <Title text="Contact" />
      <form
        className="w-full h-full center flex flex-col gap-4"
        onSubmit={handleSubmit(onSubmit)}
      >
        <label className="input input-bordered flex items-center gap-2">
          Nom complet
          <input
            type="text"
            placeholder="Prénom Nom"
            {...register("name", { required: "Name is required" })}
          />
          {errors.name && <p role="alert">{errors.name.message}</p>}
        </label>
        <label className="input input-bordered flex items-center gap-2">
          Email
          <input
            type="text"
            className="grow"
            placeholder="johndoe@gmail.com"
            {...register("email", {
              required: "Email is required",
              pattern: { value: /^\S+@\S+$/i, message: "Invalid email" },
            })}
          />
          {errors.email && <p role="alert">{errors.email.message}</p>}
        </label>
        <label className="input input-bordered flex items-center gap-2">
          Votre message
          <input
            type="text"
            placeholder="Tapez ici"
            className="grow"
            {...register("message", { required: "Message is required" })}
          />
          {errors.message && <p role="alert">{errors.message.message}</p>}
        </label>

        <button id="sendMail" className="btn btn-neutral">
          {" "}
          Envoyer
        </button>
      </form>
      <div
        id="toastSuccessMail"
        className="toast hidden toast-middle toast-center"
      >
        <div className="alert alert-success">
          <span>Message bien envoyé.</span>
        </div>
      </div>
    </ContactContainer>
  );
}

export default ContactForm;
