import React from "react";

interface TitleProps {
  text: string;
  size?: string;
  align?: string;
  color?: string;
}

const Title = ({
  text,
  size = "text-4xl",
  align = "text-center",
  color = "text-black",
}: TitleProps) => {
  return (
    <div>
      <h1
        className={`${size} ${align} ${color} font-extrabold p-10 sm:text-5xl`}
      >
        {text}
      </h1>
    </div>
  );
};

export default Title;
