import styled from "styled-components";
import photo from "../../assets/me-pro.jpeg";
import axios from "axios";
import { useQuery } from "react-query";
import { HomeContainer } from "../../utils/styles/GlobalStyle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLinkedin,
  faGithub,
  faGitlab,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope, faBusinessTime } from "@fortawesome/free-solid-svg-icons"; // ou '@fortawesome/free-regular-svg-icons'
import { useApiUrl } from "../../utils/Context/ApiContext";

const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
`;

const ImgHome = styled.div`
  max-width: 30rem;
`;

interface UserProfile {
  id: number;
  firstName: string;
  lastName: string;
  fullName: string;
  title: string;
  description: string;
  picture: string;
}

type UserProfiles = UserProfile[];

function Home() {
  const apiUrl = useApiUrl();

  const fetchUserProfile = async (): Promise<UserProfiles> => {
    const response = await axios.get<UserProfiles>(`${apiUrl}/api/profils`);
    return response.data;
  };

  const { data, error, isLoading } = useQuery<UserProfiles, Error>(
    "userProfiles", // La clé unique pour ce query
    fetchUserProfile
  );

  if (isLoading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    data &&
    data.length > 0 && (
      <HomeContainer className="sm:container sm:mx-auto grid grid-cols-1 gap-y-16 lg:grid-cols-2 lg:grid-rows-[auto_1fr] lg:gap-y-12 gap-4 mt-32">
        <LeftContainer className="lg:order-first gap-12">
          <h1 className="text-5xl font-extrabold tracking-tight sm:text-5xl">
            {data[0].title}
          </h1>
          <span className="flow-root text-2xl">{data[0].description}</span>
        </LeftContainer>
        <RightContainer className="gap-8 p-4 m-4">
          <ImgHome className="max-w-xs px-2.5 lg:max-w-none">
            <img
              alt="Christopher Rocha Dias"
              className="aspect-square rounded-2xl object-cover"
              src={photo}
            />
          </ImgHome>
          <div className="divider"></div>
          <span className="flow-root contents">
            <a
              href="https://www.malt.fr/profile/christopherdeveloppeursymfonysenior"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faBusinessTime} />
              <span className="ml-4 text-lg">Mon profil Malt</span>
            </a>
            <a
              href="https://www.linkedin.com/in/christopher-rocha-dias/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faLinkedin} />
              <span className="ml-4 text-lg">Se connecter sur LinkedIn</span>
            </a>
            <a
              href="mailto:christopher.rochadias@gmail.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faEnvelope} />
              <span className="ml-4 text-lg">
                christopher.rochadias@gmail.com
              </span>
            </a>
            <a
              href="https://github.com/chrrochadias"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faGithub} />
              <span className="ml-4 text-lg">Github</span>
            </a>
            <a
              href="https://gitlab.com/christopher.rochadias"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faGitlab} />
              <span className="ml-4 text-lg">Gitlab</span>
            </a>
          </span>
        </RightContainer>
      </HomeContainer>
    )
  );
}

export default Home;
