import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import Main from "./components/Main/Main";
import Home from "./pages/Home/Home";
import GlobalStyle from "./utils/styles/GlobalStyle";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Career from "./pages/Career/Career";
import ContactForm from "./pages/Contact/Contact";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import Projects from "./pages/Projects/Projects";
import { ApiProvider } from "./utils/Context/ApiContext";

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root")!);
root.render(
  <React.StrictMode>
    <ApiProvider>
      <QueryClientProvider client={queryClient}>
        <Router>
          <GlobalStyle>
            <Main>
              <Header />
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/career" element={<Career />} />
                <Route path="/projects" element={<Projects />} />
                <Route path="/contact" element={<ContactForm />} />
              </Routes>
              <Footer />
            </Main>
          </GlobalStyle>
        </Router>
      </QueryClientProvider>
    </ApiProvider>
  </React.StrictMode>
);
