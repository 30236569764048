import { useQuery } from "react-query";
import { HomeContainer } from "../../utils/styles/GlobalStyle";
import axios from "axios";
import styled from "styled-components";
import Title from "../../components/Title/Title";
import { useApiUrl } from "../../utils/Context/ApiContext";

interface Project {
  id: number;
  name: string;
  picture: string;
  underDescription: string;
  description: string;
  skills: [string];
  duration: string;
}

const Card = styled.div`
  min-height: 32vh;
  max-height: 32vh;
`;

type ProjectsData = Project[];

function Projects() {
  const apiUrl = useApiUrl();

  const fetchProjects = async (): Promise<ProjectsData> => {
    const response = await axios.get<ProjectsData>(`${apiUrl}/api/projects`);
    return response.data;
  };

  const { data, error, isLoading } = useQuery<ProjectsData, Error>(
    "projects", // La clé unique pour ce query
    fetchProjects
  );

  if (isLoading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;
  return (
    data &&
    data.length > 0 && (
      <>
        <Title text="Projets Notables" />
        <HomeContainer className="sm:container sm:mx-auto columns-3 grid xs:grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
          {data.map((project) => (
            <Card key={project.id} className="card bg-base-100 shadow-xl">
              <figure>
                <img src={project.picture} alt={project.name} />
              </figure>
              <div className="card-body">
                <h2 className="card-title">{project.name}</h2>
                <p>{project.description}</p>
                <div className="card-actions justify-end">
                  {/* <div className="badge badge-outline">Fashion</div>
                <div className="badge badge-outline">Products</div> */}
                </div>
              </div>
            </Card>
          ))}
        </HomeContainer>
      </>
    )
  );
}

export default Projects;
